import React from 'react';
import IntlContext from './IntlContext';
import IsNarrowViewContext from './IsNarrowViewContext';
import ThemeContext from './ThemeContext';
import Service from '../services/pinboards';
import ToastContext from './ToastContext';
import useFaviconUpdater from '../common/hooks/FaviconUpdater';

const Wrapper = props => {
  const [isGX, setIsGX] = React.useState(Service.isGX());
  const [isAir, setisAir] = React.useState(Service.isAir());

  React.useEffect(() => {
    async function checkProduct() {
      setIsGX(await Service.checkIsGX());
      setisAir(await Service.checkIsAir());
    }
    checkProduct();
  }, [setIsGX]);

  useFaviconUpdater({isAir});

  return (
    <IntlContext>
    <IsNarrowViewContext>
    <ThemeContext isGX={isGX}>
    <ToastContext>
      {props.children}
    </ToastContext>
    </ThemeContext>
    </IsNarrowViewContext>
    </IntlContext>
  );
};

export default Wrapper;
