import React from 'react';

interface FaviconUpdaterProps {
    isAir: boolean;
}

export default function useFaviconUpdater({isAir}: FaviconUpdaterProps) {
    React.useEffect(() => {
        if (isAir) {
            updateOperaAirFavicon();
        }
    }, [isAir]);
}

function updateOperaAirFavicon() {
    const faviconLink = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    faviconLink.href = '/favicon_air.ico';
}
