export const LOCAL_TOKEN = 'LOCAL_PINBOARD';

// Image upload errors
export const NOT_AN_IMAGE = 'NOT_AN_IMAGE';
export const FAILED_TO_COMPRESS = 'FAILED_TO_COMPRESS';

export const Browser = {
  OPERA: 'Opera',
  OPERA_GX: 'Opera GX',
  OPERA_AIR: 'Opera Air',
  OTHER: 'other',
};

export const PinboardNotificationTypes = {
  ReactionDeleted: 'reactionDeleted',
  ReactionsAdded: 'reactionsAdded',
  BoardDetailsUpdated: 'boardDetailsUpdated',
  ItemsAdded: 'itemsAdded',
  ItemsUpdated: 'itemsUpdated',
  ItemDeleted: 'itemDeleted',
  BoardDeleted: 'boardDeleted',
};

export const TRY_PINBOARDS_CLOSED = 'tryPinboardsBannerClosed';
